import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import withLoading from '../../../shared/components/withLoading';
import Auth from '../../authentication/Auth';
import withLogin from '../../authentication/withLogin';
import { CabecalhoContainer } from '../../cabecalho';
import { DrawerNavegacao } from '../../navegacao';
import VinculoPage from '../../vinculos';
/* istanbul ignore next */
const ToggleFeatureContainer = React.lazy(() => {
  if (Auth.isAuthenticated()) {
    return import('../../toggle');
  }
  return <Redirect to="/" />;
});
/* istanbul ignore next */
const ClientePageContainer = React.lazy(() => {
  if (Auth.isAuthenticated()) {
    return import('../../cliente');
  }
  return <Redirect to="/" />;
});
/* istanbul ignore next */
const UsuarioContainer = React.lazy(() => {
  if (Auth.isAuthenticated()) {
    return import('../../usuario');
  }
  return <Redirect to="/" />;
});

/* istanbul ignore next */
const FazendaContainer = React.lazy(() => {
  if (Auth.isAuthenticated()) {
    return import('../../fazenda');
  }
  return <Redirect to="/" />;
});
/* istanbul ignore next */
// const IngredienteContainer = React.lazy(() => {
//   if (Auth.isAuthenticated()) {
//     return import('../../ingrediente');
//   }
//   return <Redirect to="/" />;
// });

/* istanbul ignore next */
// const FabricanteContainer = React.lazy(() => {
//   if (Auth.isAuthenticated()) {
//     return import('../../fabricante');
//   }
//   return <Redirect to="/" />;
// });

/* istanbul ignore next */
const RelatorioContainer = React.lazy(() => {
  if (Auth.isAuthenticated()) {
    return import('../../relatorioTableau');
  }
  return <Redirect to="/" />;
});
export const areaRestrita = props => {
  const { classes } = props;
  return (
    <React.Fragment>
      <CabecalhoContainer />
      <div className={classes.root}>
        <DrawerNavegacao />
        <Grid direction="column" container justify="center" alignItems="flex-start">
          <main className={classes.content}>
            <Switch>
              <Route
                id="routeFuncionalidades"
                exact
                path="/funcionalidades"
                render={() => (
                  <Suspense fallback={<div />}>
                    <ToggleFeatureContainer />
                  </Suspense>
                )}
              />
              <Route
                id="routeUsuarios"
                exact
                path="/usuarios"
                render={() => (
                  <Suspense fallback={<div />}>
                    <UsuarioContainer />
                  </Suspense>
                )}
              />
              <Route
                id="routeClientes"
                exact
                path="/clientes"
                render={() => (
                  <Suspense fallback={<div />}>
                    <ClientePageContainer />
                  </Suspense>
                )}
              />
              <Route
                id="routeVinculosUsuario"
                exact
                path="/usuarios/:IdUsuario/vinculos"
                render={() => (
                  <Suspense fallback={<div />}>
                    <VinculoPage />
                  </Suspense>
                )}
              />

              <Route
                id="routeFazendas"
                exact
                path="/fazendas"
                render={() => (
                  <Suspense fallback={<div />}>
                    <FazendaContainer />
                  </Suspense>
                )}
              />

              {/* <Route
                id="routeIngredientes"
                exact
                path="/ingredientes"
                render={() => (
                  <Suspense fallback={<div />}>
                    <IngredienteContainer />
                  </Suspense>
                )}
              /> */}

              {/* <Route
                id="routeFabricantes"
                exact
                path="/fabricantes"
                render={() => (
                  <Suspense fallback={<div />}>
                    <FabricanteContainer />
                  </Suspense>
                )}
              /> */}

              <Route
                id="routeRelatorio"
                exact
                path="/relatorio"
                render={() => (
                  <Suspense fallback={<div />}>
                    <RelatorioContainer />
                  </Suspense>
                )}
              />
            </Switch>
          </main>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 8,
    paddingTop: 100,
    paddingLeft: '32px',
    paddingRigth: '32px',
    width: '100%'
  }
});

export default withLogin(withLoading(withStyles(styles)(areaRestrita)));
