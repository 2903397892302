import axios from 'axios';

const URI_CLIENTE = '/clientes/api/v1/clientes/';

class ClienteService {
  async obtemClientes() {
    return axios.get(`${URI_CLIENTE}`, { headers: {} });
  }

  async obtemClientePorFiltro(filtro) {
    const { data: resposta } = await axios.get(`${URI_CLIENTE}`)

    const respostaFiltrada = resposta.filter(cliente => {
      for (const key in filtro) {
        if (cliente[key] !== filtro[key]) {
          return false;
        }
      }
      return true;
    });
    return respostaFiltrada;
  }

  async cadastraCliente(clienteCadastrar) {
    return axios.post(`${URI_CLIENTE}`, clienteCadastrar);
  }

  async salvaCliente(IdCliente, clienteSalvar) {
    return axios.put(`${URI_CLIENTE}${IdCliente}`, clienteSalvar);
  }

  async excluiCliente(IdCliente) {
    return axios.delete(`${URI_CLIENTE}${IdCliente}`);
  }
}
export default new ClienteService();
