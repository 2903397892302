import memoize from "memoize-one";
import { normalizarString } from "../../shared/util";

export const colunasCsvClientes = {
  IdCliente: "CódigoCliente",
  ClienteProteus: "GrupoCliente",
  CodigoClienteProteus: "CódigoGrupoCliente",
  Nome: "RazãoSocial",
  NomeReduzido: "Apelido",
  TemClienteFinanceiro: "Financeiro",
  TemAcessoCentralAjuda: "CentralAjuda",
  TemLiberadoSuporte: "Suporte",
  NomeTipoCliente: "Tipo",
  DataCriacao: "DataCadastro",
  UsuarioCriacao: "UsuárioCadastro",
  DataAlteracao: "Atualização",
  UsuarioAlteracao: "UsuárioAtualização",
};

export const tiposClientes = [
  { value: 1, label: "Lite" },
  { value: 2, label: "Solteiro" },
  { value: 3, label: "STP" },
  { value: 4, label: "STP Digital" },
  { value: 5, label: "Demo" },
  { value: 6, label: "Interno" },
];

export const opcoesSelecaoUnica = [
  { value: 0, label: "SIM" },
  { value: 1, label: "NÃO" },
];

export const filterClientesMemoize = memoize(
  (
    listaClientes,
    textoFiltrar,
    filtrarCodigo,
    filtrarTipos,
    filtrarAcessoFinanceiro,
    filtrarAjuda,
    filtrarAcessoSuporte
  ) => {
    let listaFiltrada = [...(listaClientes || [])];

    if (textoFiltrar) {
      listaFiltrada = listaFiltrada.filter((cliente) => {
        const nomeNormalizado = normalizarString(cliente.Nome);
        const nomeReduzidoNormalizado = normalizarString(cliente.NomeReduzido);
        const textoFiltrarFormatado = normalizarString(textoFiltrar);
        return (
          nomeNormalizado.includes(textoFiltrarFormatado) ||
          nomeReduzidoNormalizado.includes(textoFiltrarFormatado)
        );
      });
    }

    if (filtrarCodigo) {
      listaFiltrada = listaFiltrada.filter(cliente => {
        return String(cliente.CodigoClienteProteus).includes(filtrarCodigo);
      });
    }

    if (filtrarTipos && filtrarTipos.length > 0) {
      listaFiltrada = listaFiltrada.filter((cliente) => {
        return filtrarTipos.includes(cliente.NomeTipoCliente);
      });
    }
    if (
      filtrarAcessoFinanceiro &&
      filtrarAcessoFinanceiro.length > 0 &&
      filtrarAcessoFinanceiro !== "Todos"
    ) {
      listaFiltrada = listaFiltrada.filter((cliente) => {
        return (
          cliente.TemClienteFinanceiro.toUpperCase() === filtrarAcessoFinanceiro
        );
      });
    }

    if (filtrarAjuda && filtrarAjuda.length > 0 && filtrarAjuda !== "Todos") {
      listaFiltrada = listaFiltrada.filter((cliente) => {
        return cliente.TemAcessoCentralAjuda.toUpperCase() === filtrarAjuda;
      });
    }

    if (
      filtrarAcessoSuporte &&
      filtrarAcessoSuporte.length > 0 &&
      filtrarAcessoSuporte !== "Todos"
    ) {
      listaFiltrada = listaFiltrada.filter((cliente) => {
        return (
          cliente.TemLiberadoSuporte.toUpperCase() === filtrarAcessoSuporte
        );
      });
    }
    return listaFiltrada;
  }
);
export const verificaFormValido = ({
  nome,
  nomeReduzido,
  ClienteFinanceiro,
  CNPJ,
  UsuarioFinanceiro,
  SenhaFinanceiro,
  erroCodigoProtheus,
}) => {
  const semNome = !nome.length;
  const semNomeReduzido = !nomeReduzido.length;
  const semCNPJ = !CNPJ.length;
  const semUsuarioFinanceiro = !UsuarioFinanceiro.length;
  const senhaFinanceiroInvalida =
    !SenhaFinanceiro.length || SenhaFinanceiro.length < 6;
  const clienteFinanceiroInvalido =
    ClienteFinanceiro &&
    (semCNPJ || semUsuarioFinanceiro || senhaFinanceiroInvalida);
  if (
    semNome ||
    semNomeReduzido ||
    erroCodigoProtheus ||
    clienteFinanceiroInvalido
  ) {
    return true;
  } else {
    return false;
  }
};

export const trataDadosClientes = (clientes) => {
  const obterNomeTipoCliente = (tipoCliente) => {
    const tipo = tiposClientes.find((t) => t.value === tipoCliente);
    return tipo ? tipo.label : "Desconhecido";
  };
  const tratarBooleano = (valor) => {
    return valor === true ? "Sim" : "Não";
  };

  const alterarDadosCliente = (cliente) => {
    return {
      ...cliente,
      NomeTipoCliente: obterNomeTipoCliente(cliente.TipoCliente),
      TemClienteFinanceiro: tratarBooleano(cliente.ClienteFinanceiro),
      TemAcessoCentralAjuda: tratarBooleano(cliente.AcessaCentralAjuda),
      TemLiberadoSuporte: tratarBooleano(cliente.LiberadoSuporte),
    };
  };

  const clientesTratados = clientes.map(alterarDadosCliente);

  return clientesTratados;
};
