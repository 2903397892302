const constants = {
  defaultDateFormat: "DD/MM/YYYY",
  orderBy: {
    asc: "asc",
    desc: "desc",
  },
  orderMode: {
    SERVIDOR: "SERVIDOR",
    LOCAL: "LOCAL",
  },
};

export default constants;
