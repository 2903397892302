/* eslint-disable max-len */
import { requisicaoComSucesso } from '../../shared/util/utils';
import UsuarioService from '../usuario/usuarioService';
import usuarioClienteVinculoService from '../usuarioClienteVinculo/usuarioClienteVinculoService';
import ClienteService from './clienteService';
import { trataDadosClientes } from './clienteUtils';

export const MSG = {
  ERRO_OBTER_CLIENTES: 'Ocorreu um erro ao tentar retornar todos os clientes.',
  ERRO_SALVAR_CLIENTE: 'Ocorreu erro ao tentar salvar dados do cliente',
  ERRO_EXCLUIR_CLIENTE: 'Ocorreu erro ao tentar exlcuir cliente'
};

class ClienteManager {
  async obtemClientes() {
    try {
      const respostaServicoCliente = await ClienteService.obtemClientes();
      const clientes = respostaServicoCliente.data;
      const clientesUpperCase = clientes.map(cliente => {
        cliente.Nome = cliente.Nome.toUpperCase();
        return cliente;
      });
      const collator = new Intl.Collator("pt-BR", { sensitivity: "base" });

      const clientesOrdenados = clientesUpperCase.sort((a, b) =>
        collator.compare(a.Nome, b.Nome)
      );
      const dadosClientesTratados = trataDadosClientes(clientesOrdenados);
      return dadosClientesTratados;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CLIENTES);
    }
  }

  async salvaCliente(IdCliente, clienteSalvar, usuariosAdministradores) {
    try {
      let respostaServicoCliente;
      if (IdCliente) {
        respostaServicoCliente = await ClienteService.salvaCliente(IdCliente, clienteSalvar);
      } else {
        respostaServicoCliente = await ClienteService.cadastraCliente(clienteSalvar);
      }

      if (usuariosAdministradores) {

        const clientes = await ClienteService.obtemClientePorFiltro({ Nome: clienteSalvar.Nome, Excluido: false });
        const idCliente = IdCliente || clientes[0].IdCliente;

        const { data: usuariosAdministradoresExistentes } = await UsuarioService.obtemUsuariosAdministradoresPorCliente(idCliente);

        const usuariosAdministradoresExistentesIds = usuariosAdministradoresExistentes.map(usuario => usuario.IdUsuario);
        const usuariosAdministradoresParaExcluir = usuariosAdministradoresExistentesIds.filter(id => !usuariosAdministradores.includes(id));
        const usuariosAdministradoresParaIncluir = usuariosAdministradores.filter(id => !usuariosAdministradoresExistentesIds.includes(id));

        const usuariosAdministradoresPromises = [];
        usuariosAdministradoresParaExcluir.forEach(idUsuario => {
          usuariosAdministradoresPromises.push(UsuarioService.excluiUsuarioAdministrador(idCliente, idUsuario));
        });
        usuariosAdministradoresParaIncluir.forEach(idUsuario => {
          usuariosAdministradoresPromises.push(UsuarioService.cadastraUsuarioAdministrador(idUsuario, idCliente));
        });

        await Promise.all(usuariosAdministradoresPromises);
      }

      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_SALVAR_CLIENTE);
    }
  }

  async excluiCliente(IdCliente) {
    try {
      const respostaServicoCliente = await ClienteService.excluiCliente(IdCliente);
      await usuarioClienteVinculoService.excluiVinculoUsuarioPorCliente(IdCliente);
      return requisicaoComSucesso(respostaServicoCliente.status);
    } catch (error) {
      throw new Error(MSG.ERRO_EXCLUIR_CLIENTE);
    }
  }
}
export default new ClienteManager();
