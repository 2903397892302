import * as UIActions from '../../../shared/redux/UIActions';
import { notificacaoActions } from '../../notificacao/redux';
import ClienteManager from '../clienteManager';
import * as clienteActions from './clienteActions';

export const MSG = {
  OBTEM_CLIENTES_FALHA: 'Não foi possível listar clientes',
  ATUALIZA_CLIENTE_SUCESSO: 'Cliente atualizado com sucesso',
  ATUALIZA_CLIENTE_FALHA: 'Não foi possível atualizar cliente',
  CRIA_CLIENTE_SUCESSO: 'Cliente cadastrado com sucesso',
  CRIA_CLIENTE_FALHA: 'Não foi possível cadastrar cliente',
  EXCLUI_CLIENTE_SUCESSO: 'Cliente excluido com sucesso',
  EXCLUI_CLIENTE_FALHA: 'Não foi possível excluir cliente'
};

const obtemClientes = () => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const clientes = await ClienteManager.obtemClientes();
    dispatch(clienteActions.setClientes(clientes));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message || MSG.OBTEM_CLIENTES_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const salvaCliente = (IdCliente, clienteSalvar, usuariosAdministradores) => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const clienteSalvo = await ClienteManager.salvaCliente(IdCliente, clienteSalvar, usuariosAdministradores);
    if (clienteSalvo) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(
        notificacaoActions.notificaSucesso(
          IdCliente ? MSG.ATUALIZA_CLIENTE_SUCESSO : MSG.CRIA_CLIENTE_SUCESSO
        )
      );
    } else {
      dispatch(
        notificacaoActions.notificaErro(
          IdCliente ? MSG.ATUALIZA_CLIENTE_FALHA : MSG.CRIA_CLIENTE_FALHA
        )
      );
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message || MSG.ATUALIZA_CLIENTE_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

const excluiCliente = IdCliente => async dispatch => {
  try {
    dispatch(UIActions.setLoading());
    const clienteExcluido = await ClienteManager.excluiCliente(IdCliente);
    if (clienteExcluido) {
      const clientes = await ClienteManager.obtemClientes();
      dispatch(clienteActions.setClientes(clientes));
      dispatch(notificacaoActions.notificaSucesso(MSG.EXCLUI_CLIENTE_SUCESSO));
    } else {
      dispatch(notificacaoActions.notificaErro(MSG.EXCLUI_CLIENTE_FALHA));
    }
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message || MSG.EXCLUI_CLIENTE_FALHA));
  } finally {
    dispatch(UIActions.setLoading());
  }
};

export { excluiCliente, obtemClientes, salvaCliente };

